import React from 'react';
import Swal from 'sweetalert2';
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import $ from "jquery";
import "select2/dist/js/select2.min.js";
import "select2/dist/css/select2.min.css";

let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let hoy =
  f[2].padStart(2, "0") +
  "-" +
  f[1].padStart(2, "0") +
  "-" +
  f[0].padStart(2, "0");

class Listar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      ListaTabla: [],
      ListaEstados: [],
      ListaCliente: [],
      ListaCiudades: [],
      ListaCiudadesFiltradas: [],
      ListaDepartamentos: [],
      ListaAseguradoras: [],
      ListaPrefactura: [],
      ListaEstadosSiniestros: [],
      Hoy: hoy,
      seleccionarTodo: false,
      idsSeleccionados: [],
      clientesConBoton: [],
      idClienteInfo: [],
      modalEstadoVisible: false,
      selectedEstado: "",
      estados: [],
      modalInfoVisible: false,
      subtotal: 0,
      modalVisible: false,
      selectedItem: null, // Item seleccionado para cambiar conductor
      conductores: [], // Lista de conductores
      selectedConductor: "",
      tooltip: {
        visible: false,
        x: 0,
        y: 0,
        item: null,
      },
      Usuario: localStorage.getItem('Name')
    }
  }


  closeModal = () => {
    this.setState({ modalVisible: false });
  };
  openModalInfo = (item) => {
    this.setState({
      modalInfoVisible: true,
      selectedItem: this.state.tooltip.item,
    });
  };

  closeModalInfo = () => {
    this.setState({ modalInfoVisible: false });
  };
  openModalEstado = (item) => {
    console.log(this.state.tooltip.item);
    this.setState({
      modalEstadoVisible: true,
      selectedItem: this.state.tooltip.item,
      selectedEstado: this.state.tooltip.item.estado_servicio, // Selecciona el estado actual
    });
  };

  closeModalEstado = () => {
    this.setState({ modalEstadoVisible: false });
  };
  handleRowClick = (event, item) => {
    const rect = event.target.getBoundingClientRect();
    this.setState({
      tooltip: {
        visible: true,
        x: rect.x + window.scrollX,
        y: rect.y + window.scrollY,
        item,
      },
    });
  };
  actualizarConductor(id) {
    Swal.showLoading();
    const data = {
      conductor: this.state.selectedConductor
    };
    authAxios.post("exterior/actualizar-conductor/" + id, data)
      .then((datosRespuesta) => {
        Swal.close();
        var m = datosRespuesta.data.msj;

        Swal.fire({
          title: m,
          text: 'Ok',
          icon: 'success',
          showConfirmButton: true, // Mostrar el botón "Confirmar"
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.MostrarTabla();
          }
        });
        // this.setState({ idClienteInfo: datosRespuesta.data.datos });
        // this.setState({ datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  actualizarEstado(id) {
    Swal.showLoading();
    const { selectedEstado } = this.state;
    const data = {
      estado: selectedEstado
    };
    authAxios.post("exterior/actualizar-estado/" + id, data)
      .then((datosRespuesta) => {

        var m = datosRespuesta.data.msj;

        Swal.fire({
          title: m,
          text: 'Ok',
          icon: 'success',
          showConfirmButton: true, // Mostrar el botón "Confirmar"
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.MostrarTabla();
            Swal.close();
          }
        });
        // this.setState({ idClienteInfo: datosRespuesta.data.datos });
        // this.setState({ datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // Método para cerrar el tooltip
  closeTooltip = () => {
    this.setState({
      tooltip: { visible: false, x: 0, y: 0, item: null },
    });
  };
  openModal = (item) => {
    this.setState({
      modalVisible: true,
      selectedItem: this.state.tooltip.item,
      // Conductor actual
    });
  };
  // Agrega un manejador de eventos para el cambio en el campo de selección de departamento
  handleDepartamentoChange = (event) => {
    const departamentoSeleccionado = event.target.value;

    // Filtra la lista de ciudades en función del departamento seleccionado
    const ciudadesFiltradas = this.state.ListaCiudades.filter(
      (ciudad) => ciudad.departamento === departamentoSeleccionado
    );

    this.setState({
      ListaCiudadesFiltradas: ciudadesFiltradas,
    });
  };
  exportToExcel = () => {
    let idx = document.getElementById('idprefactura').value;
    const { ListaPrefactura } = this.state;

    const ws = XLSX.utils.json_to_sheet(ListaPrefactura);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');

    XLSX.writeFile(wb, 'Prefactura' + idx + '.xlsx');
  };
  consultarClienteId(identificacion) {
    console.log(this.state.clientesConBoton)
    Swal.showLoading();
    authAxios.post("exterior/consulta-id-cliente/" + identificacion)
      .then((datosRespuesta) => {
        Swal.close();
        var respuesta = datosRespuesta.data.datos[0];
        console.log(datosRespuesta.data);
        // document.getElementById("tipoDocumento").value = respuesta.tipoDocumento;
        document.getElementById("nombres").value = respuesta.nombre;
        document.getElementById("telefono").value = respuesta.telefono;
        // document.getElementById("departamento").value = respuesta.departamento;
        document.getElementById("documento").value = respuesta.identificacion;
        document.getElementById("email").value = respuesta.email;
        document.getElementById("apellidos").value = respuesta.apellido;
        // document.getElementById("ciudad").value = respuesta.ciudad;
        document.getElementById("direccion").value = respuesta.direccion;

        // this.setState({ idClienteInfo: datosRespuesta.data.datos });
        // this.setState({ datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras });
      })
      .catch((err) => {
        console.log(err);
      });
  }


  GenerarSiigo(id) {
    Swal.showLoading();
    authAxios.post("exterior/exterior-siigo/" + id)
      .then((datosRespuesta) => {
        Swal.close();
        var m = datosRespuesta.data.msj;

        Swal.fire({
          title: m,
          text: 'Ok',
          icon: 'success',
          showConfirmButton: true, // Mostrar el botón "Confirmar"
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.MostrarTabla();
          }
        });
        // this.setState({ idClienteInfo: datosRespuesta.data.datos });
        // this.setState({ datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  guardarCliente = (e) => {

    e.preventDefault();
    Swal.showLoading();
    // Recopila los datos del formulario en un objeto data
    const data = {
      tipoDocumento: document.getElementById('tipoDocumento').value,
      nombres: document.getElementById('nombres').value,
      telefono: document.getElementById('telefono').value,
      departamento: document.getElementById('departamento').value,
      documento: document.getElementById('documento').value,
      email: document.getElementById('email').value,
      apellidos: document.getElementById('apellidos').value,
      ciudad: document.getElementById('ciudad').value,
      direccion: document.getElementById('direccion').value,
    };
    this.setState({ clientesConBoton: [] }, () => {
      // Código que depende del estado actualizado
      // Realiza la solicitud a la API con los datos del formulario
      authAxios.post('exterior/guardar-cliente', data)
        .then(response => {
          Swal.close();
          console.log(this.state.clientesConBoton)
          this.MostrarTabla(1);
          // Maneja la respuesta de la API si es necesario
          console.log(response.data);
          const miBoton = document.getElementById('miBoton');

          // Simula un clic en el botón
          miBoton.click();
        })
        .catch(error => {
          // Maneja los errores si la solicitud falla
          console.error(error);
        });
    });

  }
  componentWillUnmount() {
    clearInterval(this.intervalId); // Limpiar el intervalo cuando el componente se desmonta
  }
  MostrarTabla() {
    this.setState({ load: true, clientesConBoton: [] });
    // Swal.showLoading();
    let bfecha = document.getElementById('bfecha').value;
    let bfecha2 = document.getElementById('bfecha2').value;
    let origen = document.getElementById('borigen').value;
    let estado = document.getElementById('bestado').value;
    let exp = document.getElementById('exp').value;


    var datos = { origen: origen, fechai: bfecha, fechaf: bfecha2, exp: exp, estado: estado }
    this.setState({ clientesConBoton: [] }, () => {
      authAxios.post("exterior/consultaservicio-tiemporeal", datos)
        .then((datosRespuesta) => {
          Swal.close();
          var status = datosRespuesta.data.status;

          this.setState({ datosCargados: true, load: '', ListaTabla: datosRespuesta.data.datos, clientesConBoton: [] });
        })
        .catch(() => {
          this.setState({ clientesConBoton: [] });
        });
    })
    //window.location.href = '/'; window.localStorage.clear();
  }

  handleSelectConductor = (selectedOption) => {
    console.log("Selected:", selectedOption);
    this.setState({ selectedConductor: selectedOption });
  };


  MostrarClientes() {

    authAxios.get("exterior/consultacliente")
      .then((datosRespuesta) => {
        Swal.close();
        var status = datosRespuesta.data.status;
        console.log(datosRespuesta.data);
        this.setState({
          datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras, ListaCiudades: datosRespuesta.data.ciudades,
          ListaDepartamentos: datosRespuesta.data.departamentos,
          ListaEstadosSiniestros: datosRespuesta.data.estados_siniestro,
          estados: datosRespuesta.data.estados_siniestro,
          conductores: datosRespuesta.data.conductores
        });
      })
      .catch();
    //window.location.href = '/'; window.localStorage.clear();
  }
  Enviar = () => {
    let id = document.getElementById('idprefactura').value;
    var dati = { id: id }
    authAxios.post("exterior/consultarprefactura", dati)
      .then((datosRespuesta) => {
        Swal.close();
        var status = datosRespuesta.data.status;
        console.log(datosRespuesta.data.datos);
        this.setState({ ListaPrefactura: datosRespuesta.data.datos });
      })
      .catch();
    //window.location.href = '/'; window.localStorage.clear();
  }
  UpValores(id) {
    const { idsSeleccionados } = this.state;
    let valor = document.getElementById('valor' + id).value;
    var datos = { id: id, valor: valor }
    console.log(idsSeleccionados);
    authAxios.post("exterior/actualizavalor", datos)
      .then((datosRespuesta) => {

        var status = datosRespuesta.data.status;
        this.setState((prevState) => {
          const updatedListaTabla = prevState.ListaTabla.map(item => {
            if (item.id === id) {
              return { ...item, valor_servicio: valor };
            }
            return item;
          });
          return { ListaTabla: updatedListaTabla };
        }, this.calcularSubtotal);

      })
      .catch();
    //window.location.href = '/'; window.localStorage.clear();
  }
  GuardarValores = () => {
    Swal.fire({
      title: 'Está seguro de Generar la prefactura?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Generar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.showLoading();
        let origen = document.getElementById('borigen').value;
        const { idsSeleccionados, Usuario } = this.state;
        if (idsSeleccionados.length === 0) {
          Swal.fire(
            'Debes de seleccionar los servicios',
            '',
            'warning'
          )
          return false;
        }

        var datos = { id: idsSeleccionados, usuario: Usuario, origen: origen }
        console.log(idsSeleccionados);
        authAxios.post("exterior/guardarseleccionado", datos)
          .then((datosRespuesta) => {
            var m = datosRespuesta.data.msj;
            var status = datosRespuesta.data.status;
            document.getElementById('bfecha').value = '';
            document.getElementById('bfecha2').value = '';
            document.getElementById('borigen').value = '';
            document.getElementById('bestado').value = '';
            document.getElementById('ase').value = '';
            document.getElementById('exp').value = '';
            this.setState({
              idsSeleccionados: [],
              subtotal: 0,
            });
            Swal.fire({
              title: m,
              text: 'Facturado',
              icon: 'success',
              showConfirmButton: true, // Mostrar el botón "Confirmar"
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                this.MostrarTabla();
              }
            });
          })
          .catch();
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info')
      }
    })
  }
  AnularValores = () => {
    Swal.fire({
      title: 'Está seguro de Anular los servicios seleccionados?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Generar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        let origen = document.getElementById('borigen').value;
        const { idsSeleccionados, Usuario } = this.state;
        if (idsSeleccionados.length === 0) {
          Swal.fire(
            'Debes de seleccionar los servicios',
            '',
            'warning'
          )
          return false;
        }

        var datos = { id: idsSeleccionados, usuario: Usuario, origen: origen }
        console.log(idsSeleccionados);
        authAxios.post("exterior/anularseleccionado", datos)
          .then((datosRespuesta) => {
            var m = datosRespuesta.data.msj;
            var status = datosRespuesta.data.status;
            Swal.fire({
              title: m,
              text: 'Facturado',
              icon: 'success',
              showConfirmButton: true, // Mostrar el botón "Confirmar"
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                this.MostrarTabla();
              }
            });

          })
          .catch();
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info')
      }
    })
  }
  handleSelectAll = () => {
    this.setState((prevState) => {
      const { ListaTabla, seleccionarTodo } = prevState;
      const nuevosIdsSeleccionados = !seleccionarTodo ? ListaTabla.map((item) => item.id) : [];
      return {
        seleccionarTodo: !seleccionarTodo,
        idsSeleccionados: nuevosIdsSeleccionados,
      };
    }, this.calcularSubtotal);
  };

  handleCheckboxChange = (id) => {
    this.setState((prevState) => {
      const { idsSeleccionados } = prevState;
      const updatedIds = idsSeleccionados.includes(id) ? idsSeleccionados.filter(itemId => itemId !== id) : [...idsSeleccionados, id];
      return { idsSeleccionados: updatedIds };
    }, this.calcularSubtotal);
  };
  calcularSubtotal = () => {
    const { ListaTabla, idsSeleccionados } = this.state;
    const subtotal = ListaTabla.reduce((total, item) => {
      console.log(item);
      return idsSeleccionados.includes(item.id) ? total + parseFloat(item.facturado) : total;
    }, 0);
    this.setState({ subtotal });
  }
  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.MostrarTabla();
    }, 15000); // 15 segundos
    this.MostrarClientes(1);
    $("#selectConductor").select2({
      placeholder: "Seleccione un conductor",
      allowClear: true,
    });
    $("#borigen").select2({
      placeholder: "Seleccione un cliente",
      allowClear: true,
    });


  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.modalVisible && !prevState.modalVisible) {
      // Reaplica Select2 si el modal se vuelve visible
      $("#selectConductor").select2({
        placeholder: "Seleccione un conductor",
        allowClear: true,
      });
      $("#borigen").select2({
        placeholder: "Seleccione un cliente",
        allowClear: true,
      });
      $("#selectConductor").on("change", (e) => {
        console.log("log");
        this.setState({ selectedConductor: e.target.value });
      });
    }
  }
  render() {
    const { load, ListaCiudadesFiltradas, ListaTabla, ListaEstadosSiniestros, subtotal, pagina, ultimo, ListaPrefactura, actual, Hoy, ListaAseguradoras, ListaEstados, ListaCliente, seleccionarTodo, idsSeleccionados, ListaDepartamentos, ListaCiudades, tooltip } = this.state;
    return (
      <div className="content">
        <div className="container-fluid">
          <br />
          <div className="card">

            <div className="card-body">

              <h4>Lista Servicios En tiempo Real</h4>
              <hr />
              <div className="row">
                <div className="col-xl-2">
                  <select id="borigen" name="borigen" className="form-control form-control-sm" >
                    <option value={''}>Seleccione el cliente</option>
                    {
                      ListaCliente
                        .map((item, i) => (
                          <option key={i} value={item.id}>{item.nombre}</option>
                        ))
                    }
                  </select>
                </div>
                <div className="col-xl-2">
                  <select id="bestado" name="bestado" className="form-control form-control-sm" >
                    <option value={''}>Seleccione estado</option>
                    {
                      ListaEstadosSiniestros.map((ite, i) => (
                        <option key={ite.id} value={ite.id}>{ite.id} - {ite.estado}</option>
                      ))}
                  </select>
                </div>

                <div className="col-xl-2">
                  <input style={{ textTransform: 'uppercase' }} type="text" id="exp" name="exp" placeholder="Buscar por expediente" className="form-control form-control-sm" />
                </div>
                <div className="col-xl-2">
                  <input style={{ textTransform: 'uppercase' }} type="date" id="bfecha" name="bfecha" placeholder="Buscar por expediente" className="form-control form-control-sm" />
                </div>
                <div className="col-xl-2">
                  <input style={{ textTransform: 'uppercase' }} type="date" id="bfecha2" name="bfecha2" placeholder="Buscar por fecha" className="form-control form-control-sm" />
                </div>
                <div className="col-xl-2">
                  <button id="bt" name="bt" className="btn btn-primary btn-sm" onClick={() => this.MostrarTabla(1)}>&nbsp;&nbsp;Filtrar</button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "15px",
                  padding: "10px",
                }}
              >
                {ListaEstadosSiniestros.map((estado) => (
                  <div
                    key={estado.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: estado.color,
                        marginRight: "8px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                      }}
                    ></div>
                    <span>{estado.estado}</span>
                  </div>
                ))}
              </div>
              {this.state.load ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Cargando...</span>
                  </div>
                </div>
              ) : ''}
              <br /><br />
              <div className="row table-responsive">
                <table className="table-hover table-condensed table-sm">
                  <thead className="thead-inverse table-success">
                    <tr>
                      <th className="align-middle">ID</th>
                      <th className="align-middle">NUMERO EXP</th>
                      <th className="align-middle">CLIENTE</th>
                      <th className="align-middle">FECHA SERVICIO</th>
                      <th className="align-middle">HORA</th>
                      <th className="align-middle">PLACA</th>
                      <th className="align-middle">CONDUCTORES</th>
                      <th className="align-middle">ESTADO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ListaTabla.map((item) => (
                      <tr
                        key={item.id}
                        style={{
                          background: `${item.color_estado}`,
                          cursor: "pointer",
                          transition: "background-color 0.3s", // Suaviza la transición
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.filter = "brightness(0.85)") // Oscurece el color
                        }
                        onMouseLeave={(e) => (e.currentTarget.style.filter = "brightness(1)")} // Restaura el color original
                        onClick={(e) => this.handleRowClick(e, item)}
                      >
                        <td className="text-white">{item.id}</td>
                        <td className="text-white">{item.orden_servicio_new}</td>
                        <td className="text-white">{item.nombre_cliente}</td>
                        <td className="text-white">{item.fecha}</td>
                        <td className="text-white">{item.hora_inicial}</td>
                        <td className="text-white">{item.placa_vh}</td>
                        <td className="text-white">{item.nombre_conductor}</td>
                        <td className="text-white">{item.estado_nombre}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {tooltip.visible && (
                  <div
                    style={{
                      position: "absolute",
                      top: tooltip.y,
                      left: tooltip.x,
                      backgroundColor: "#fff",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      zIndex: 1000,
                      padding: "10px",
                      width: "500px"
                    }}
                  >
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => this.openModalEstado(tooltip.selectedItem)}
                    >
                      Cambiar Estado
                    </button>
                    <button
                      className="btn btn-warning btn-sm mx-2"
                      onClick={() => this.openModal(tooltip.selectedItem)}
                    >
                      Cambiar Conductor
                    </button>
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => this.openModalInfo(tooltip.selectedItem)}
                    >
                      Ver Información
                    </button>
                    <button
                      className="btn btn-danger btn-sm mt-2"
                      onClick={this.closeTooltip}
                      style={{ display: "block", marginTop: "10px" }}
                    >
                      Cerrar
                    </button>
                  </div>
                )}
              </div>

            </div>
            <div className="card-footer text-muted">
              <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
            </div>
          </div>
        </div>
        {this.state.modalVisible && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              borderRadius: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",

              padding: "20px",
              width: "400px",
            }}
          >
            <h5>Cambiar Conductor - Servicio {this.state.selectedItem?.orden_servicio_new}</h5>
            <p><strong>Conductor Actual:</strong> {this.state.selectedItem?.nombre_conductor}</p>
            <div className="form-group">
              <label htmlFor="selectConductor">Seleccionar Nuevo Conductor:</label>
              <select
                id="selectConductor"
                className="form-control"
                style={{ zIndex: 9999 }}
                value={this.state.selectedConductor}
                onChange={this.handleSelectConductor}
                placeholder="Seleccione un conductor"
              >
                {this.state.conductores.map((conductor, index) => (
                  <option key={index} value={conductor.identificacion}>
                    {conductor.nombre}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={() => {
                console.log("Nuevo Conductor:", this.state.selectedConductor);
                this.actualizarConductor(this.state.selectedItem.id);
                this.closeModal();
              }}
            >
              Guardar
            </button>
            <button
              className="btn btn-secondary mt-3 ml-2"
              onClick={this.closeModal}
            >
              Cancelar
            </button>
          </div>
        )}
        {this.state.modalEstadoVisible && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              borderRadius: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              padding: "20px",
              width: "400px",
            }}
          >
            <h5>Cambiar Estado - Servicio {this.state.selectedItem?.orden_servicio_new}</h5>
            <p>
              <strong>Estado Actual:</strong>{" "}
              {this.state.selectedItem?.estado_nombre}
            </p>
            <div className="form-group">
              <label htmlFor="selectEstado">Seleccionar Nuevo Estado:</label>
              <select
                id="selectEstado"
                className="form-control"
                style={{ zIndex: 9999 }}
                value={this.state.selectedEstado}
                onChange={(e) =>
                  this.setState({ selectedEstado: e.target.value })
                }
              >
                {this.state.estados.map((estado, index) => (
                  <option key={index} value={estado.id}>
                    {estado.estado}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={() => {
                console.log("Nuevo Estado:", this.state.selectedEstado);
                this.actualizarEstado(this.state.selectedItem.id);
                this.closeModalEstado();
              }}
            >
              Guardar
            </button>
            <button
              className="btn btn-secondary mt-3 ml-2"
              onClick={this.closeModalEstado}
            >
              Cancelar
            </button>
          </div>
        )}
        {this.state.modalInfoVisible && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              borderRadius: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              padding: "20px",
              width: "500px",
              zIndex: "99999",
            }}
          >
            <h4 className="mb-4">Información Detallada - {this.state.selectedItem?.nombre_cliente}</h4>

            <div>
              <h5 className="text-primary">General</h5>
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <strong>Expediente:</strong> {this.state.selectedItem?.orden_servicio_new}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Estado:</strong> {this.state.selectedItem?.estado_nombre}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Teléfono:</strong> {this.state.selectedItem?.numero_contacto}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Fecha:</strong> {this.state.selectedItem?.fecha}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <p>
                    <strong>Hora:</strong> {this.state.selectedItem?.hora_inicial}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Placa:</strong> {this.state.selectedItem?.placa_vh}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Origen:</strong> {this.state.selectedItem?.direccion_inicial}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Destino:</strong> {this.state.selectedItem?.direccion_final}
                  </p>
                </div>
                <div className="col-md-12">
                  <p>
                    <strong>Observaciones:</strong> {this.state.selectedItem?.observaciones}
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <h5 className="text-primary">Conductor</h5>
              <p>
                <strong>Nombre:</strong> {this.state.selectedItem?.nombre_conductor}
              </p>
              <p>
                <strong>Identificación:</strong>{" "}
                {this.state.selectedItem?.conductor}
              </p>
              <p>
                <strong>Telefono:</strong> {this.state.selectedItem?.movil_conductor}
              </p>
            </div>
            <hr />

            <button
              className="btn btn-secondary mt-3"
              onClick={this.closeModalInfo}
            >
              Cerrar
            </button>
          </div>
        )}

        {/* modal crear clinente */}
        <div className="modal fade" id="FormularioModalCreacionCliente" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
          <div className={"modal-dialog  modal-lg"} role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="permisoRolesModalLabel">Creacion Cliente </h4>
                <button id='miBoton' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <input type="hidden" id="item" name="item" />
              </div>
              <div className="modal-body">
                <div id="form_registro_crear_cliente">

                  <div className="card-body">
                    <div className="card-body btn-showcase">
                      <div id="formulario">
                        <div className="row">
                          <div className="col-xl-12">
                            <form onSubmit={this.guardarCliente}>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="tipoDocumento">Tipo Documento</label>
                                    <select class="form-control" id="tipoDocumento" aria-describedby="tipoDocumentoHelp">
                                      <option value="1">CC</option>
                                      <option value="2">CE</option>
                                      <option value="3">NIT</option>
                                    </select>
                                    <small id="tipoDocumentoHelp" class="form-text text-muted">Selecciona el tipo de documento.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="nombres">Nombres</label>
                                    <input type="text" class="form-control" id="nombres" aria-describedby="nombresHelp" />
                                    <small id="nombresHelp" class="form-text text-muted">Ingresa tu nombre.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="telefono">Telefono</label>
                                    <input type="tel" class="form-control" id="telefono" aria-describedby="telefonoHelp" />
                                    <small id="telefonoHelp" class="form-text text-muted">Ingresa tu número de teléfono.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="departamento">Departamento</label>
                                    {/* <input type="text" class="form-control" id="departamento" aria-describedby="departamentoHelp" /> */}
                                    <select id="departamento" onChange={this.handleDepartamentoChange} name="departamento" className="form-control form-control-sm">
                                      <option value={''}>Seleccione el departamento</option>
                                      {
                                        ListaDepartamentos.map((item, i) => (
                                          <option value={item.id} >{item.id} - {item.nombre}</option>
                                        ))}
                                    </select>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="documento">Documento</label>
                                    <input type="text" class="form-control" id="documento" aria-describedby="documentoHelp" />
                                    <small id="documentoHelp" class="form-text text-muted">Ingresa tu número de documento.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="email">Email</label>
                                    <input type="email" class="form-control" id="email" aria-describedby="emailHelp" />
                                    <small id="emailHelp" class="form-text text-muted">Ingresa tu dirección de correo electrónico.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="apellidos">Apellidos</label>
                                    <input type="text" class="form-control" id="apellidos" aria-describedby="apellidosHelp" />
                                    <small id="apellidosHelp" class="form-text text-muted">Ingresa tus apellidos.</small>
                                  </div>
                                  <div class="form-group">
                                    <label for="ciudad">Ciudad</label>
                                    <select id="ciudad" name="ciudad" className="form-control form-control-sm">
                                      <option value={''}>Seleccione el ciudad</option>
                                      {
                                        ListaCiudadesFiltradas.map((item, i) => (
                                          <option value={item.id} >{item.id} - {item.nombre}</option>
                                        ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="direccion">Direccion</label>
                                <input type="text" class="form-control" id="direccion" aria-describedby="direccionHelp" />
                                <small id="direccionHelp" class="form-text text-muted">Ingresa tu dirección.</small>
                              </div>
                              <button type="submit" class="btn btn-primary mt-4">Guardar</button>
                            </form>




                          </div>
                        </div>

                      </div>

                      <div className="modal-footer">
                        {/* <button onClick={this.exportToExcel} className="btn btn-primary">Guardar Cliente</button> */}
                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </div>

          </div>
        </div >


        <div className="modal fade" id="FormularioModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
          <div className={"modal-dialog  modal-xl"} role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="permisoRolesModalLabel">Detalle del servicio <span id='servicio'></span></h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <input type="hidden" id="item" name="item" />
              </div>
              <div className="modal-body">
                <div id="form_registro">

                  <div className="card-body">
                    <div className="card-body btn-showcase">
                      <div id="formulario">
                        <div className="row">
                          <div className="col-xl-12">
                            <div className='row g-3'>


                              <div className="col-md-12">
                                <label htmlFor="idprefactura">Id Prefactura </label>
                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm mt-2 mb-4"} type="text" name="idprefactura" id="idprefactura" aria-describedby="helpId" />

                              </div>



                            </div>

                            <table className="table table-hover table-condensed table-sm">

                              <thead className="thead-inverse  table-success ">
                                <tr>
                                  <th className="align-middle">ITEM</th>
                                  <th className="align-middle">PREFACTURA</th>
                                  <th className="align-middle">DOCUMENTO</th>
                                  <th className="align-middle">NOMBRE USUARIO</th>
                                  <th className="align-middle">CANT DE SERVICIO</th>
                                  <th className="align-middle">TOTAL</th>
                                  <th className="align-middle">ACCION</th>

                                </tr>
                              </thead>
                              <tbody>

                                {
                                  ListaPrefactura.map((item, i) => (
                                    <tr key={item.id}>
                                      <td>{i + 1}</td>
                                      <td>{item.id_prefactura}</td>
                                      <td>{item.documento}</td>
                                      <td>{item.nombre_cliente}</td>
                                      <td>{item.servicio}</td>
                                      <td>{item.total}</td>
                                      <td><button className='btn btn-primary btn-sm' onClick={() => this.GenerarSiigo(item.id_prefactura)}> Generar SIIGO </button></td>

                                    </tr>
                                  ))
                                }
                              </tbody>

                            </table>

                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col-xl-12 text-center'>
                            <div className="btn-group" role="group" aria-label="">
                              <button type="button" onClick={this.Enviar} className="btn btn-success btn-sm" id="btnservicio"><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Consultar Prefactura</button>&nbsp;&nbsp;


                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button onClick={this.exportToExcel} className="btn btn-primary">Descargar Excel</button>
                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>




      </div >
    );
  }
}

export default Listar;