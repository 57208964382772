import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Swal from 'sweetalert2';
import authAxios from "../../services/Token";

// Create styles
const styles = StyleSheet.create({
   page: {
      flexDirection: 'row',
      backgroundColor: '#ffffff'
   },
   section: {
      margin: 10,
      padding: 10,
      paddingLeft: 10,
      flexGrow: 1,
      fontSize: 10,
      // border: 1
   },
   section2: {
      margin: 10,
      padding: 200,
      paddingLeft: 10,
      flexGrow: 1,
      fontSize: 12,
      alignItems: "center"
   },
   baseText: {
      fontWeight: 'bold',
      fontSize: 9,
   },
   innerText: {
      color: 'black',
      fontWeight: 'bold'
   },


});

// Create Document Component
const MyDocument = (item) => {



   const [Cliente, setCliente] = React.useState("Cargando..");
   const [FechaRegistro, setFechaRegistro] = React.useState("");
   const [DocumentoCliente, setDocumentoCliente] = React.useState("Cargando..");
   const [EmpresaAfiliada, setEmpresaAfiliada] = React.useState("Cargando..");
   const [Contrato, setContrato] = React.useState("Cargando..");
   const [Responsable, setResponsable] = React.useState("Cargando..");
   const [ResponsableCedula, setResponsableCedula] = React.useState("Cargando..");
   const [ResponsableTelefono, setResponsableTelefono] = React.useState("Cargando..");
   const [ResponsableDireccion, setResponsableDireccion] = React.useState("Cargando..");
   const [Origen, setOrigen] = React.useState("Cargando..");
   const [Destino, setDestino] = React.useState("Cargando..");
   const [Inicio, setInicio] = React.useState("Cargando..");
   const [Fin, setFin] = React.useState("Cargando..");
   const [Placa, setPlaca] = React.useState("Cargando..");
   const [Modelo, setModelo] = React.useState("Cargando..");
   const [Marca, setMarca] = React.useState("Cargando..");
   const [Clase, setClase] = React.useState("Cargando..");
   const [Interno, setInterno] = React.useState("Cargando..");
   const [Tarjeta, setTarjeta] = React.useState("Cargando..");

   const [Nombre, setNombre] = React.useState("Cargando..");
   const [Cedula, setCedula] = React.useState("Cargando..");
   // const [Licencia, setLicencia] = React.useState("Cargando..");
   const [Vigencia, setVigencia] = React.useState("Cargando..");

   const [Nombre2, setNombre2] = React.useState("");
   const [Cedula2, setCedula2] = React.useState("");
   // const [Licencia2, setLicencia2] = React.useState("");
   const [Vigencia2, setVigencia2] = React.useState("");

   const [Nombre3, setNombre3] = React.useState("");
   const [Cedula3, setCedula3] = React.useState("");
   const [Empresa, setEmpresa] = React.useState("");
   // const [Licencia3, setLicencia3] = React.useState("");
   const [Vigencia3, setVigencia3] = React.useState("");
   const [Consecutivo, setConsecutivo] = React.useState("");
   const [FechaFuec, setFechaFuec] = React.useState("");
   const [ContratoNumero, setContratoNumero] = React.useState("");
   const [contratoCargado, setContratoCargado] = React.useState(false);

   React.useEffect(() => {
      VerRegistros(item.item.id);

   }, [item.item.id]);

   const VerRegistros = async (fue) => {
      if (fue != undefined) {
         Swal.showLoading();
         authAxios.get(`master/fuec/${fue}`)
            .then((datosRespuesta) => {


               let veh = datosRespuesta.data.vehiculo;
               let tarjeta_op = datosRespuesta.data.tarjeta_op;
               let con = datosRespuesta.data.contrato;
               let cli = datosRespuesta.data.cliente;
               let cc = datosRespuesta.data.conductor;
               let cc2 = datosRespuesta.data.conductor2;
               let fuec = datosRespuesta.data.fuec;
               
               let lin = datosRespuesta.data.linea;
               let cla = datosRespuesta.data.clase;
               let rec = datosRespuesta.data.recorridos;
               let empresa = datosRespuesta.data.empresa;
               setContratoNumero(con.numero);
               setFechaRegistro(fuec.fecha_registro);
               setFechaFuec(new Date(veh.fecha_registro).getFullYear());
               setConsecutivo(fuec.id);
               setEmpresaAfiliada(veh.empresaafiliada);
               setEmpresa(empresa.fuec);
               setCliente(cli.nombres + ' ' + cli.apellidos);
               setDocumentoCliente(cli.documento);
               setContrato(con.descripcion);
               setResponsableDireccion(con.direccion)
               setResponsableTelefono(con.telefono)
               setResponsableCedula(con.cedula)
               setResponsable(con.responsable_contrato)
               setOrigen(rec.origen);
               setDestino(rec.desino);
               setInicio(new Date(con.fecha_inicio));
               setFin(new Date(con.fecha_fin));
               setPlaca(veh.placa);
               setModelo(veh.modelo);
               setMarca(lin.nombre);
               setClase(cla.nombre);
               setInterno(veh.interno);
               setTarjeta(tarjeta_op.numero_poliza);

               setNombre(cc.nombre + ' ' + cc.apellidos);
               setCedula(cc.identificacion);
               // setLicencia(cc.identificacion);
               setVigencia(cc.fech_vencpase);

               if (cc2 !== null) {
                  setNombre2(cc2.nombre + ' ' + cc2.apellidos);
                  setCedula2(cc2.identificacion);
                  // setLicencia2(cc2.identificacion);
                  setVigencia2(cc2.fech_vencpase);
               } else {
                  setNombre2("");
                  setCedula2("");
                  // setLicencia2("");
                  setVigencia2("");
               }
               setContratoCargado(true);

               Swal.close();


            })
            .catch();
      }



   };

   return (
      <>
         {contratoCargado && (
            <Document>
               <Page size="A4" style={styles.page}>
                  <View style={styles.section} >
                     <Text style={{ textAlign: "center", fontWeight: 'bold' }} >FICHA DEL FORMATO DEL EXTRACTO DEL CONTRATO “FUEC”</Text>
                     <Image src={require("../../assets/images/header2.png")} alt="" style={{ width: 550, height: 70, textAlign: "center" }} />

                     <Text style={{ textAlign: "center", fontWeight: 'bold' }} >FORMATO ÚNICO DEL EXTRACTO DEL CONTRATO DEL SERVICIO PÚBLICO DE TRANSPORTE TERRESTRE{"\n"} AUTOMOTOR ESPECIAL</Text>
                     <Text style={{ textAlign: "center" }} >FUEC No.: <Text style={{ color: '#6E6E6E' }}>{Empresa}{FechaFuec}{ContratoNumero}{Consecutivo} </Text></Text>
                     <Text>___________________________________________________________________________________________________</Text>
                     <View style={{ flexDirection: 'row', borderLeftWidth: 1, borderRightWidth: 1, borderTopWidth: 1 }}>
                        <View style={{ width: 350, padding: 2 }}>
                           <Text>
                              RAZÓN SOCIAL DE LA EMPRESA DE TRANSPORTE ESPECIAL:
                           </Text>
                        </View>
                        <View style={{ width: 150 }}>
                           <Text>
                              NIT:
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row', borderLeftWidth: 1, borderRightWidth: 1, borderBottomWidth: 1 }}>
                        <View style={{ width: 350, padding: 2 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              TRANSPORTES ESPECIALES DEL ORIENTE S.A.S
                           </Text>
                        </View>
                        <View style={{ width: 150 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              900645942-6
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row', border: 1 }}>
                        <View style={{ width: 350, padding: 2 }}>
                           <Text>
                              CONTRATO No: {ContratoNumero}
                           </Text>
                        </View>

                     </View>
                     <View style={{ flexDirection: 'row', borderLeftWidth: 1, borderRightWidth: 1, borderTopWidth: 1 }}>
                        <View style={{ width: 350, padding: 2 }}>
                           <Text>
                              CONTRATANTE:
                           </Text>
                        </View>
                        <View style={{ width: 150 }}>
                           <Text>
                              NIT-CC:
                           </Text>
                        </View>
                     </View>

                     <View style={{ flexDirection: 'row', borderLeftWidth: 1, borderRightWidth: 1, borderBottomWidth: 1, padding: 2 }}>
                        <View style={{ width: 350, padding: 2 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Cliente}
                           </Text>
                        </View>
                        <View style={{ width: 150 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {DocumentoCliente}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row', border: 1, padding: 2 }}>
                        <Text style={styles.baseText} >OBJETO CONTRATO: <Text style={{ color: '#6E6E6E' }}>{Contrato}</Text></Text>
                     </View>
                     <View style={{ flexDirection: 'column', borderWidth: 1, padding: 2 }}>
                        <Text style={styles.baseText}>
                           ORIGEN: <Text style={{ color: '#6E6E6E' }}>{Origen}</Text>
                        </Text>
                        <Text style={{ fontWeight: 'bold', marginTop: 4 }}>
                           DESTINO: <Text style={{ color: '#6E6E6E' }}>{Destino}</Text>
                        </Text>
                     </View>
                     <View style={{ flexDirection: 'row', border: 1, padding: 2 }}>
                        <Text style={{ fontWeight: 'bold' }} >CONVENIO: <Text style={{ color: '#6E6E6E' }}>x</Text> <Text style={{ color: '#000' }}>CONSORCIO</Text> <Text style={{ color: '#6E6E6E' }}> </Text><Text style={{ color: '#000' }}>UNION TEMPORAL:</Text> <Text style={{ color: '#000' }}>{EmpresaAfiliada}</Text></Text>
                     </View>
                     <Text style={{ textAlign: "center", backgroundColor: '#B8CCE4', border: 1, padding: 2 }} >VIGENCIA DEL CONTRATO</Text>
                     <View style={{ flexDirection: 'row', border: 1, padding: '3px' }}>
                        <View style={{ width: 250 }}>
                           <Text>
                              FECHA DE INICIO:
                           </Text>
                        </View>
                        <View style={{ width: 250 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              <Text style={{ color: '#000', fontWeight: 'bold' }}>Día:</Text> {Inicio.getDate()} &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;    <Text style={{ color: '#000', fontWeight: 'bold' }}>Mes:</Text> {Inicio.toLocaleString('default', { month: 'long' })} &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;   <Text style={{ color: '#000', fontWeight: 'bold' }}>Año:</Text> {Inicio.getFullYear()}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row', border: 1, padding: '3px' }}>
                        <View style={{ width: 250 }}>
                           <Text >
                              FECHA DE VENCIMIENTO:
                           </Text>
                        </View>
                        <View style={{ width: 250 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              <Text style={{ color: '#000', fontWeight: 'bold' }}>Día:</Text> {Fin.getDate()} &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;    <Text style={{ color: '#000', fontWeight: 'bold' }}>Mes:</Text> {Fin.toLocaleString('default', { month: 'long' })} &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;   <Text style={{ color: '#000', fontWeight: 'bold' }}>Año:</Text> {Fin.getFullYear()}
                           </Text>
                        </View>
                     </View>
                     <Text style={{ textAlign: "center", backgroundColor: '#B8CCE4', border: 1, padding: 2 }} >CARACTERISTICAS DEL VEHICULO</Text>
                     <View style={{ flexDirection: 'row', border: 1 }}>
                        <View style={{ width: 125, borderRight: 1, padding: 2 }}>
                           <Text >
                              PLACA
                           </Text>
                        </View>
                        <View style={{ width: 125, borderRight: 1, padding: 2 }}>
                           <Text >
                              MODELO
                           </Text>
                        </View>
                        <View style={{ width: 125, borderRight: 1, padding: 2 }}>
                           <Text >
                              MARCA
                           </Text>
                        </View>
                        <View style={{ width: 125, padding: 1 }}>
                           <Text >
                              CLASE
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row', border: 1, fontSize: 10 }}>
                        <View style={{ width: 125, borderRight: 1, padding: 2 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Placa}
                           </Text>
                        </View>
                        <View style={{ width: 125, borderRight: 1, padding: 2 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Modelo}
                           </Text>
                        </View>
                        <View style={{ width: 125, borderRight: 1, padding: 2 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Marca}
                           </Text>
                        </View>
                        <View style={{ width: 125, padding: 1 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Clase}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row', border: 1 }}>
                        <View style={{ width: 250, borderRight: 1, padding: 2 }}>
                           <Text style={{ textAlign: "center" }} >
                              NUMERO INTERNO
                           </Text>
                        </View>
                        <View style={{ width: 250, padding: 2 }}>
                           <Text style={{ textAlign: "center" }} >
                              NUMERO TARJETA DE OPERACION
                           </Text>
                        </View>

                     </View>
                     <View style={{ flexDirection: 'row', border: 1 }}>
                        <View style={{ width: 250, borderRight: 1, padding: 2 }}>
                           <Text style={{ color: '#6E6E6E', textAlign: "center" }}>
                              {Interno}
                           </Text>
                        </View>
                        <View style={{ width: 250, padding: 2 }}>
                           <Text style={{ color: '#6E6E6E', textAlign: "center" }}>
                              {Tarjeta}
                           </Text>
                        </View>

                     </View>
                     <View style={{ flexDirection: 'row', border: 1 }}>
                        <View style={{ width: 125, borderRight: 1, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>{"\n"}
                              DATOS DEL{"\n"}
                              CONDUCTOR 1
                           </Text>
                        </View>
                        <View style={{ width: 125, borderRight: 1, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>
                              NOMBRES Y APELLIDOS {"\n"}{"\n"}
                           </Text>
                           {"\n"}
                           <Text >
                              {Nombre}
                           </Text>
                        </View>
                        <View style={{ width: 125, borderRight: 1, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>
                              No CEDULA {"\n"}{"\n"}
                           </Text>
                           {"\n"}
                           <Text style={{ fontSize: 9 }}>
                              {Cedula}
                           </Text>
                        </View>
                        <View style={{ width: 125, borderRight: 1, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>
                              No LICENCIA DE {"\n"}
                              CONDUCCION {"\n"}{"\n"}
                           </Text>
                           {"\n"}
                           <Text >
                              {Cedula}
                           </Text>
                        </View>
                        <View style={{ width: 125, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>
                              VIGENCIA {"\n"}{"\n"}
                           </Text>
                           {"\n"}
                           <Text >
                              {Vigencia}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row', border: 1 }}>
                        <View style={{ width: 125, borderRight: 1, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>{"\n"}
                              DATOS DEL{"\n"}
                              CONDUCTOR 2
                           </Text>
                        </View>
                        <View style={{ width: 125, borderRight: 1, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>
                              NOMBRES Y APELLIDOS {"\n"}{"\n"}
                           </Text>
                           {"\n"}
                           <Text >
                              {Nombre2}
                           </Text>
                        </View>
                        <View style={{ width: 125, borderRight: 1, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>
                              No CEDULA {"\n"}{"\n"}
                           </Text>
                           {"\n"}
                           <Text style={{ fontSize: 9 }}>
                              {Cedula2}
                           </Text>
                        </View>
                        <View style={{ width: 125, borderRight: 1, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>
                              No LICENCIA DE {"\n"}
                              CONDUCCION {"\n"}{"\n"}
                           </Text>
                           {"\n"}
                           <Text >
                              {Cedula2}
                           </Text>
                        </View>
                        <View style={{ width: 125, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>
                              VIGENCIA {"\n"}{"\n"}
                           </Text>
                           {"\n"}
                           <Text >
                              {Vigencia2}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row', border: 1 }}>
                        <View style={{ width: 125, borderRight: 1, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>{"\n"}
                              DATOS DEL{"\n"}
                              CONDUCTOR 3
                           </Text>
                        </View>
                        <View style={{ width: 125, borderRight: 1, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>
                              NOMBRES Y APELLIDOS {"\n"}{"\n"}
                           </Text>
                           {"\n"}
                           <Text >
                              {Nombre3}
                           </Text>
                        </View>
                        <View style={{ width: 125, borderRight: 1, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>
                              No CEDULA {"\n"}{"\n"}
                           </Text>
                           {"\n"}
                           <Text style={{ fontSize: 9 }}>
                              {Cedula3}
                           </Text>
                        </View>
                        <View style={{ width: 125, borderRight: 1, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>
                              No LICENCIA DE {"\n"}
                              CONDUCCION {"\n"}{"\n"}
                           </Text>
                           {"\n"}
                           <Text >
                              {Cedula3}
                           </Text>
                        </View>
                        <View style={{ width: 125, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>
                              VIGENCIA {"\n"}{"\n"}
                           </Text>
                           {"\n"}
                           <Text >
                              {Vigencia3}
                           </Text>
                        </View>
                     </View>
                     {/* <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              No. CEDULA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Cedula}
                           </Text>
                        </View>
                     </View> */}
                     {/* <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              No. LICENCIA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Cedula}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              FECHA DE VIGENCIA LICENCIA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Vigencia}
                           </Text>
                        </View>

                     </View> */}
                     {/* <Text style={{ textAlign: "center", backgroundColor: '#A9D0F5' }} >DATOS BASICO CONDUCTOR 2</Text>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              NOMBRE Y APELLIDOS:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Nombre2}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              No. CEDULA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Cedula2}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              No. LICENCIA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Cedula2}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              FECHA DE VIGENCIA LICENCIA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Vigencia2}
                           </Text>
                        </View>

                     </View>
                     <Text style={{ textAlign: "center", backgroundColor: '#A9D0F5' }} >DATOS BASICO CONDUCTOR 3</Text>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              NOMBRE Y APELLIDOS:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Nombre3}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              No. CEDULA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Cedula3}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              No. LICENCIA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Cedula3}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: 150 }}>
                           <Text >
                              FECHA DE VIGENCIA LICENCIA:
                           </Text>
                        </View>
                        <View style={{ width: 350 }}>
                           <Text style={{ color: '#6E6E6E' }}>
                              {Vigencia3}
                           </Text>
                        </View>

                     </View> */}
                     <View style={{ flexDirection: 'row', border: 1 }}>
                        <View style={{ width: 125, borderRight: 1, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>{"\n"}
                              DATOS DEL {"\n"}CONTRATANTE
                           </Text>
                        </View>
                        <View style={{ width: 125, borderRight: 1, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>
                              NOMBRES Y APELLIDOS {"\n"}{"\n"}
                           </Text>
                           {"\n"}
                           <Text >
                              {Responsable}
                           </Text>
                        </View>
                        <View style={{ width: 125, borderRight: 1, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>
                              No CEDULA {"\n"}{"\n"}
                           </Text>
                           {"\n"}
                           <Text style={{ fontSize: 9 }}>
                              {ResponsableCedula}
                           </Text>
                        </View>
                        <View style={{ width: 125, borderRight: 1, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>
                              TELEFONO {"\n"}{"\n"}
                           </Text>
                           {"\n"}
                           <Text >
                              {ResponsableTelefono}
                           </Text>
                        </View>
                        <View style={{ width: 125, padding: 1 }}>
                           <Text style={{ fontSize: 9 }}>
                              DIRECCION {"\n"}{"\n"}
                           </Text>
                           {"\n"}
                           <Text >
                              {ResponsableDireccion}
                           </Text>
                        </View>
                     </View>
                     <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                        <Image
                           src={require("../../assets/images/Imagen2.png")}
                           alt=""
                           style={{ width: 100, height: 40 }}
                        />
                        <Text style={{ marginRight: 10, fontSize: 7, textAlign: 'right' }}>FECHA DE ELAB:  {FechaRegistro}</Text>
                     </View>

                     <View style={{ flexDirection: 'row', border: 1, marginRight: 10 }}>
                        <View style={{ width: 280,borderRight:1 }}>
                           <Text>
                              {'\n'}{'\n'}¨FORMATO ÚNICO DEL EXTRACTO DEL CONTRATO SEGÚN DECRETO 0348 DE 2015 Y RESOLUCIÓN 0006652 DE 2019¨{'\n'}{'\n'}
                           </Text>
                        </View>
                        <View style={{ width: 220, borderRight: '1px solid #000' }}>
                           <Text style={{ color: '#E6E6E6', textAlign: "right" }}>
                              <Image src={require("../../assets/images/firma.png")} alt="" style={{ width: 700, height: 70, textAlign: "right" }} />
                           </Text>
                        </View>

                     </View>

                     <Text style={{ textAlign: "center" }}>{'\n'}{'\n'}TRANSPORTES ESPECIALES DEL ORIENTE S.A.S {'\n'}
                        CALLE 64A # 21 - 50 EDIF. PORTAL DEL CABLE {'\n'}
                        TEL. 3045049860 - 3046690903{'\n'}
                        MANIZALES - CALDAS</Text>


                  </View>




               </Page>
            </Document >)
         }
      </>
   )
}

export default MyDocument;