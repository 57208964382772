// dashbaord
import Default from '../components/dashboard/Index'



//nuevos usuarios
import Usuarios from '../components/usuarios/index'
import EditarUsuario from '../components/usuarios/Editar'
import CrearUsuario from '../components/usuarios/Crear'
import Perfil from '../components/usuarios/Profile'

import Roles from '../components/roles/Index'
import CrearRoles from '../components/roles/Crear'
import EditarRoles from '../components/roles/Editar'

import ListaClientes from '../components/clientes/Index'
import CrearClientes from '../components/clientes/Crear'
import EditarClientes from '../components/clientes/Editar'

import ListarTipoContrato from '../components/contratos/Index'
import ListarServiciosInternos from '../components/contratos/ServiciosInternos'
import CargueMasivoAdicionales from '../components/contratos/CargueMasivo'
import CargueMasivoEjecuciones from '../components/contratos/CargueMasivoEjecuciones'
import ListarCaracol from '../components/contratos/Caracol'
import RealServicio from '../components/contratos/service_real'
import CrearTipoContrato from '../components/contratos/Crear'
import EditarTipocontrato from '../components/contratos/Editar'

import ListarLinea from '../components/lineas/Index'
import Crearlinea from '../components/lineas/Crear'
import EditarLinea from '../components/lineas/Editar'

import ListarMarca from '../components/marca/Index'
import CrearMarca from '../components/marca/Crear'
import EditarMarca from '../components/marca/Editar'

import ListarBanco from '../components/bancos/Index'
import CrearBanco from '../components/bancos/Crear'
import EditarBanco from '../components/bancos/Editar'

import ListarEps from '../components/bancos/Index'
import CrearEps from '../components/bancos/Crear'
import EditarEps from '../components/bancos/Editar'

import ListarArl from '../components/arl/Index'
import CrearArl from '../components/arl/Crear'
import EditarArl from '../components/arl/Editar'

import ListarModulos from '../components/modulos/Index'
import CrearModulos from '../components/modulos/Crear'
import EditarModulos from '../components/modulos/Editar'

import ListarConfiguracion from '../components/configuracion/Index'
import CrearConfiguracion from '../components/configuracion/Crear'
import EditarConfiguracion from '../components/configuracion/Editar'

import Master from '../components/Master/Index';
import Control from '../components/Control/index';
import Tablero from '../components/Control/tablero';
import Servicio from '../components/Control/servicios';
import ServicioConcluido from '../components/Control/serviciosConcluido';
import Recuperar from '../auth/recuperar';

import Facturacion from '../components/Master/Facturacion';

import ListarExternos from '../components/externo/Index'
import ReporteServicios from '../components/externo/ReporteServicios'
import ListarExternosServicios from '../components/externo/ListarExternosServicios'
import PreoperacionalMasivo from '../components/externo/PreoperacionalMasivo'
import Alertas from '../components/dashboard/alerts'

export const routes = [

        { path: `${process.env.PUBLIC_URL}/recuperar`, Component: <Recuperar /> },

        { path: `${process.env.PUBLIC_URL}/pages/:idmenu/:layout`, Component: <Master /> },
        { path: `${process.env.PUBLIC_URL}/pages/Facturacion/:layout`, Component: <Facturacion /> },


        { path: `${process.env.PUBLIC_URL}/pages/usuarios/:layout`, Component: <Usuarios /> },
        { path: `${process.env.PUBLIC_URL}/pages/EditarUsuario/:layout`, Component: <EditarUsuario /> },
        { path: `${process.env.PUBLIC_URL}/pages/AddUsuarios/:layout`, Component: <CrearUsuario /> },
        { path: `${process.env.PUBLIC_URL}/pages/perfil/:layout`, Component: <Perfil /> },

        { path: `${process.env.PUBLIC_URL}/roles/lista/:layout`, Component: <Roles /> },
        { path: `${process.env.PUBLIC_URL}/roles/crear/:layout`, Component: <CrearRoles /> },
        { path: `${process.env.PUBLIC_URL}/roles/editar/:layout`, Component: <EditarRoles /> },

        { path: `${process.env.PUBLIC_URL}/clientes/lista/:layout`, Component: <ListaClientes /> },
        { path: `${process.env.PUBLIC_URL}/clientes/crear/:layout`, Component: <CrearClientes /> },
        { path: `${process.env.PUBLIC_URL}/clientes/editar/:layout`, Component: <EditarClientes /> },

        { path: `${process.env.PUBLIC_URL}/tipo_contrato/lista/:layout`, Component: <ListarTipoContrato /> },
        { path: `${process.env.PUBLIC_URL}/tipo_contrato/crear/:layout`, Component: <CrearTipoContrato /> },
        { path: `${process.env.PUBLIC_URL}/tipo_contrato/editar/:layout`, Component: <EditarTipocontrato /> },

        { path: `${process.env.PUBLIC_URL}/lineas/lista/:layout`, Component: <ListarLinea /> },
        { path: `${process.env.PUBLIC_URL}/lineas/crear/:layout`, Component: <Crearlinea /> },
        { path: `${process.env.PUBLIC_URL}/lineas/editar/:layout`, Component: <EditarLinea /> },

        { path: `${process.env.PUBLIC_URL}/marca/lista/:layout`, Component: <ListarMarca /> },
        { path: `${process.env.PUBLIC_URL}/marca/crear/:layout`, Component: <CrearMarca /> },
        { path: `${process.env.PUBLIC_URL}/marca/editar/:layout`, Component: <EditarMarca /> },

        { path: `${process.env.PUBLIC_URL}/bancos/lista/:layout`, Component: <ListarBanco /> },
        { path: `${process.env.PUBLIC_URL}/bancos/crear/:layout`, Component: <CrearBanco /> },
        { path: `${process.env.PUBLIC_URL}/bancos/editar/:layout`, Component: <EditarBanco /> },

        { path: `${process.env.PUBLIC_URL}/eps/lista/:layout`, Component: <ListarEps /> },
        { path: `${process.env.PUBLIC_URL}/eps/crear/:layout`, Component: <CrearEps /> },
        { path: `${process.env.PUBLIC_URL}/eps/editar/:layout`, Component: <EditarEps /> },
        
        { path: `${process.env.PUBLIC_URL}/arl/lista/:layout`, Component: <ListarArl /> },
        { path: `${process.env.PUBLIC_URL}/arl/crear/:layout`, Component: <CrearArl /> },
        { path: `${process.env.PUBLIC_URL}/arl/editar/:layout`, Component: <EditarArl /> },

        { path: `${process.env.PUBLIC_URL}/modulos/lista/:layout`, Component: <ListarModulos /> },
        { path: `${process.env.PUBLIC_URL}/modulos/crear/:layout`, Component: <CrearModulos /> },
        { path: `${process.env.PUBLIC_URL}/modulos/editar/:layout`, Component: <EditarModulos /> },

        { path: `${process.env.PUBLIC_URL}/configuracion/lista/:layout`, Component: <ListarConfiguracion /> },
        { path: `${process.env.PUBLIC_URL}/configuracion/crear/:layout`, Component: <CrearConfiguracion /> },
        { path: `${process.env.PUBLIC_URL}/configuracion/editar/:layout`, Component: <EditarConfiguracion /> },

        { path: `${process.env.PUBLIC_URL}/servicios/caracol/:layout`, Component: <ListarTipoContrato /> },
        { path: `${process.env.PUBLIC_URL}/servicios/caracol/servicios-interno/:layout`, Component: <ListarServiciosInternos /> },
        { path: `${process.env.PUBLIC_URL}/servicios/caracol/cargue-masivo-adicionales/:layout`, Component: <CargueMasivoAdicionales /> },
        { path: `${process.env.PUBLIC_URL}/servicios/caracol/cargue-masivo-ejecuciones/:layout`, Component: <CargueMasivoEjecuciones /> },
        { path: `${process.env.PUBLIC_URL}/caracol/servicios/:layout`, Component: <ListarCaracol /> },
        { path: `${process.env.PUBLIC_URL}/real/servicios/:layout`, Component: <RealServicio /> },
        { path: `${process.env.PUBLIC_URL}/servicios/externo/:layout`, Component: <ListarExternos /> },
        { path: `${process.env.PUBLIC_URL}/servicios/reporte-servicios/:layout`, Component: <ReporteServicios /> },
        { path: `${process.env.PUBLIC_URL}/servicios/externo-servicios/:layout`, Component: <ListarExternosServicios /> },
        { path: `${process.env.PUBLIC_URL}/vehiculo/preoperacional-masivo/:layout`, Component: <PreoperacionalMasivo /> },


        //------------------nuevos modulos------------------------
        { path: `${process.env.PUBLIC_URL}/dashboard/default/:layout/`, Component: <Default /> },
        { path: `${process.env.PUBLIC_URL}/control/:layout/`, Component: <Control /> },
        { path: `${process.env.PUBLIC_URL}/tablero/:layout/`, Component: <Tablero /> },
        { path: `${process.env.PUBLIC_URL}/servicios/:layout/`, Component: <Servicio /> },
        { path: `${process.env.PUBLIC_URL}/servicios_concluidos/:layout/`, Component: <ServicioConcluido /> },
        { path: `${process.env.PUBLIC_URL}/alertas/:alerta/:layout`, Component: <Default /> },
       
       
]